<template>
  <el-tabs>
    <el-tab-pane label="Zeiterfassung">
      <FormTimetracking />
    </el-tab-pane>
    <el-tab-pane label="Reisekosten">
      <FormTravelTracking />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import FormTimetracking from '@/components/Form/FormTimetracking';
import FormTravelTracking from '@/components/Form/FormTravelTracking';

export default {
  name: 'TabFormTimetrackingTravelTracking',

  components: {
    FormTimetracking,
    FormTravelTracking
  }
};
</script>
